<template>
  <div class="container">
    <h1 class="stamps-heading">Stamps</h1>
    <div class="dropdown">
      <VWDropdown :options="options" v-model="selectedOption" :show-labels="false" placeholder="Select category" />
    </div>
    <div v-for="(group, index) in targetGroups" :key="index">
      <div v-if="!selectedOption || selectedOption === group.name || selectedOption === 'Select All'">
        <h2 class="group-name">{{ group.name }}</h2>
        <span class="stamps-found__bold"> {{ group.completedTargets }} </span>
        <span class="stamps-found"> of </span>
        <span class="stamps-found__bold"> {{ group.targets.length }} </span>
        <span class="stamps-found"> stamps found </span>
        <div class="stamps-container">
          <div
            v-for="(stamp, index) in group.targets"
            :key="index"
            class="box-1 clue-placer"
            :class="{ complete: stamp.complete }"
          >
            <router-link v-if="stamp.complete" :to="`/target/${stamp.id}`">
              <img class="stamps-images" :src="getStampUrl(stamp.iconSrc)" />
            </router-link>
            <img v-else class="stamps-images" :src="getStampUrl(stamp.iconSrc)" />
            <Clue v-if="clue" />
          </div>
        </div>
        <hr class="divider" />
      </div>
    </div>
  </div>
</template>

<script>
import Clue from '@/components/Clue.vue';
import VWDropdown from '@/components/VWDropdown.vue';
import { ApiService } from '@/services/api.service';

export default {
  name: 'Login',
  components: {
    Clue,
    VWDropdown,
  },
  data() {
    return {
      showNav: false,
      clue: false,
      isClue: true,
      selectedOption: null,
      options: ['Select All'],
      targetGroups: null,
    };
  },

  mounted() {
    this.getTargetGroups();
  },
  methods: {
    getStampUrl(slug) {
      return `${process.env.VUE_APP_ASSET_URL}/${slug}`;
    },
    async getTargetGroups() {
      await ApiService.get(
        '/targetGroup/?limit=10&offset=0&includeTargets=true&includeDraftGroups=false&includeDraftTargets=false',
      ).then((response) => {
        this.targetGroups = response.data.targetGroups;
        response.data.targetGroups.forEach((targetGroup) => {
          this.options.push(targetGroup.name);
        });
      });
      this.targetGroups.forEach((targetGroup) => {
        const completedTargets = targetGroup.targets.filter((target) => target.complete);
        this.$set(targetGroup, 'completedTargets', completedTargets.length);
      });
    },
    hintDisplay() {
      this.$router.push('/hint');
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  margin-top: 40px;
}
.stamps-heading {
  font-size: 34px;
  font-weight: normal;
  letter-spacing: -0.85px;
}
.dropdown {
  width: 85%;
  font-weight: bold;
  @include bp-sm-phone-landscape {
    width: 470px;
  }
}
.stamps-container {
  // display: flex;
  // flex-wrap: wrap;
  // justify-content: center;
  // width: 95%;
  // margin: 0 auto;
  display: grid;
  grid-template-columns: auto auto;
  @include bp-sm-phone-landscape {
    grid-template-columns: auto auto auto;
  }
}
.group-name {
  font-size: 28px;
  font-weight: bold;
  letter-spacing: -0.42px;
}
.stamps-images {
  display: block;
  width: 150px;
  height: 150px;
  margin-top: 1em;
  margin-right: 0.5em;
  margin-bottom: 0.2em;
  margin-left: 0.5em;
}
.clue-placer {
  display: flex;
  filter: opacity(50%);

  &.complete {
    filter: none;
  }
}
.stamps-found {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.6px;
  &__bold {
    font-weight: bold;
  }
}
.divider {
  width: 55%;
  margin-top: 1em;
}

.logo {
  display: block;
  width: 2.5em;
  margin-top: 2em;
  margin-right: auto;
  margin-bottom: 8em;
  margin-left: auto;
}
</style>
