<template>
  <component :is="'vue-multiselect'" v-bind="{ ...$attrs, ...$props }" v-on="$listeners" class="g-dropdown">
    <!-- Add default slots -->
    <!-- <template v-slot:caret="{ toggle, isOpen }"> </template> -->
    <slot v-for="(_, name) in $slots" :name="name" />
    <template v-for="(_, name) in $scopedSlots" v-slot:[name]="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
  </component>
</template>

<script>
import VueMultiselect from 'vue-multiselect';
/**
 * @version 0.0.2
 */
export default {
  name: 'GDropdown',
  extends: VueMultiselect,
  inheritAttrs: false,
  components: {
    VueMultiselect,
  },
  methods: {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
@import '~vue-multiselect/dist/vue-multiselect.min.css';
::v-deep .multiselect__option--highlight {
  color: $primary-dark;
  background: $primary-white;
}
::v-deep .multiselect__option {
  font-size: 18px;
  font-weight: normal;
}
::v-deep .multiselect__tags {
  font-size: 18px;
  font-weight: normal;
}
::v-deep .multiselect__input {
  font-size: 18px;
}
</style>
