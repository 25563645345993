<template>
  <component :is="'GDropdown'" v-bind="{ ...$attrs, ...$props }" v-on="$listeners" class="vw-dropdown">
    <slot v-for="(_, name) in $slots" :name="name" />
    <template v-for="(_, name) in $scopedSlots" v-slot:[name]="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
  </component>
</template>

<script>
import GDropdown from '@/components/GDropdown.vue';

export default {
  name: 'VWDropdown',
  inheritAttrs: false,
  extends: GDropdown,
  props: {
    showLabels: {
      default: false,
    },
  },
  components: {
    GDropdown,
  },
};
</script>

<style lang="scss" scoped>
.vw-dropdown {
  font-family: inherit;
  &.multiselect--disabled {
    ::v-deep .multiselect__select {
      background-color: transparent;
    }
  }
  ::v-deep .multiselect {
    line-height: normal;
    color: inherit;

    &__input,
    &__single {
      padding-top: 5px;
      margin-bottom: 13px;
      font-size: inherit;
    }
    &__select {
      height: 100%;
      &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 16px;
        height: 8px;
        margin: -4px 0 0 -8px;
        background: url('~@/assets/icons/carat.svg?external') center center no-repeat;
        background-size: 16px 8px;
        border: 0;
      }
    }
    &__tags {
      min-height: 32px;
      border-color: $light-grey;
    }
    &__placeholder {
      padding-left: 5px;
    }
    &__option {
      &--highlight {
        color: inherit;
        background-color: $light-grey;
      }
      &--selected {
        background-color: $primary-white;

        &.multiselect__option--highlight {
          color: inherit;
          background-color: $light-grey;
        }
      }
    }
  }
}
</style>
