<template>
  <div class="clue">
    <span> Clue available </span>
  </div>
</template>

<script>
export default {
  name: 'Clue',
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
.clue {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 114px;
  height: 29px;
  margin-top: 1.2em;
  margin-left: 3em;
  font-size: 12px;
  text-transform: uppercase;
  background: $primary-color;
  box-shadow: 0 3px 6px #00000029;
}
</style>
